export default {
  id: "echigokyuryo_dem_with_ellipsoidal_height_v2",
  name: "地形",
  type: "basemap",
  index: 1,
  asset_id: 770371,
  access_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5N2UyMjcwOS00MDY1LTQxYjEtYjZjMy00YTU0ZTg5MmViYWQiLCJpZCI6ODAzMDYsImlhdCI6MTY0Mjc0ODI2MX0.dkwAL1CcljUV7NA7fDbhXXnmyZQU_c-G5zRx8PtEcxE",
  version: "2023/10/4",
}
